import {Pressable, Text} from 'native-base';
import React from 'react';
import {isWeb} from '../../helpers/utils/utils';

const QuestionChips = ({question, onSelectChip = () => {}}) => {
  return (
    <Pressable
      testID={`chip-${question?.title}`}
      onPress={() => {
        onSelectChip(question);
      }}
      px={2}
      py={2}
      bg={'secondary.30'}
      _pressed={{
        backgroundColor: 'secondary.50',
      }}
      borderRadius={'full'}>
      <Text
        numberOfLines={2}
        textAlign={'center'}
        fontSize={'sm'}
        fontWeight={800}
        fontFamily={isWeb() ? 'Recoleta' : 'recoleta'}>
        {question?.title}
      </Text>
    </Pressable>
  );
};

export default QuestionChips;
