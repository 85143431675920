// export const LOGIN_TYPES = {
//   LOGIN_PHONE_NO_PASSWORD: 'LOGIN_PHONE_NO_PASSWORD', // ✅7 Es el mismo que REGISTER_PHONE
//   LOGIN_PHONE_PASSWORD: 'LOGIN_PHONE_PASSWORD', // ✅6
//   LOGIN_EMAIL: 'LOGIN_EMAIL', //1  ✅
//   REGISTER_PHONE: 'REGISTER_PHONE', // 5✅
//   REGISTER_EMAIL: 'REGISTER_EMAIL', // 2 ✅
//   LOGIN_PHONE_PASSWORD_B2B: 'LOGIN_PHONE_PASSWORD_B2B', // ✅8
//   LOGIN_EMAIL_B2B: 'LOGIN_EMAIL_B2B', //3 ✅
//   REGISTER_PHONE_B2B: 'REGISTER_PHONE_B2B', // ✅9
//   REGISTER_EMAIL_B2B: 'REGISTER_EMAIL_B2B', //4 ✅
//   REGISTER_POLIZA_PHONE_B2B: 'REGISTER_POLIZA_PHONE_B2B', //PENDIENTES
//   REGISTER_POLIZA_EMAIL_B2B: 'REGISTER_POLIZA_EMAIL_B2B', // PENDIENTES
// };

export const LOGIN_SCREENS = {
  // STARTING: 'STARTING',
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  LOGIN_EMPLOYEE_NUMBER: 'LOGIN_EMPLOYEE_NUMBER',
  CODE: 'CODE',
  PWRECOVERY: 'PWRECOVERY',
  NEWPASSWORD: 'NEWPASSWORD',
};

export const LOGIN_TYPES = {
  REGISTER_PHONE: 'REGISTER_PHONE',
  REGISTER_EMAIL: 'REGISTER_EMAIL',
  LOGIN_EMAIL: 'LOGIN_EMAIL',
  LOGIN_EMAIL_B2B: 'LOGIN_EMAIL_B2B',
  LOGIN_PHONE: 'LOGIN_PHONE',
  LOGIN_EMPLOYEE_NUMBER: 'LOGIN_EMPLOYEE_NUMBER',
  LOGIN_EXTERNAL: 'LOGIN_EXTERNAL',
  RECOVERY_EMAIL: 'RECOVERY_EMAIL',
  REGISTER_EMAIL_B2B: 'REGISTER_EMAIL_B2B',
  RECOVERY_PHONE: 'RECOVERY_PHONE',
  LOGIN_PHONE_PASSWORD: 'LOGIN_PHONE_PASSWORD',
  LOGIN_PHONE_NO_PASSWORD: 'LOGIN_PHONE_NO_PASSWORD',
};

export const MESSAGE_TYPES = {
  NO_CONTENT: 'NO_CONTENT', //eL usuario mandó vacío (eso se va a validar quizá se quite este tipo)
  NO_BOT: 'NO_BOT', // El mensaje es del usuario
  BOT_TEXT: 'BOT_TEXT', //Mindsurf  mandó un mensaje normal
  BOT_VIDEO: 'BOT_VIDEO', // Mindsurf mandó un video
  BOT_AUDIO: 'BOT_AUDIO', // Mindsurf mandó un audio
  BOT_IMAGE: 'BOT_IMAGE', //Mindsurf mandó un arte
  BOT_EXERCISE_AUDIO: 'BOT_EXERCISE_AUDIO', // Mindsurf mandó un audio de ejercicio
  BOT_EXERCISE_VIDEO: 'BOT_EXERCISE_VIDEO', // Mindsurf mandó un video de ejercicio
  BOT_EXERCISE_CHAT: 'BOT_EXERCISE_CHAT', // Mindsurf mandó un chat de ejercicio
  BOT_EVALUATION: 'BOT_EVALUATION', // Mindsurf mandó una evaluación
  BOT_DIGITAL_THERAPY: 'BOT_DIGITAL_THERAPY', // Mindsurf mandó una terapia digital
  BOT_VOICE: 'BOT_VOICE', // Mindsurf mandó un mensaje de voz
  BOT_THERAPY: 'BOT_THERAPY', // Mindsurf mandó una terapia
};

export const LANGUAGES = ['es', 'en'];

export const EMOTIONS_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const CHAT_MODULE = {
  DIARY: 'DIARY',
  HISTORY: 'HISTORY',
  PROGRAMV2: 'PROGRAMV2',
  GPT: 'GPT',
  EXERCISES: 'EXERCISES',
  PROGRAM: 'PROGRAM',
};

export const AB_STATUS = {
  FREE_TRIAL: 'FREE_TRIAL',
  FREE_TRIAL_OVER: 'FREE_TRIAL_OVER',
  BASIC: 'BASIC',
  UNLIMITED: 'UNLIMITED',
  THERAPY: 'THERAPY',
  FREE: 'Gratis',
};

export const MODULE_NAMES = {
  PROGRAMS: 'programs',
  EXERCISES: 'exercises',
  DIARY_ENTRY: 'diaryEntry',
  REPORTS: 'reports',
  EVALUATIONS: 'evaluations',
  SESSION_INCLUDED: 'sessionsIncluded',
  LIVES: 'lives',
  THERAPY: 'therapy',
  CHAT_GPT: 'chatGPT',
  EMOTIONAL_ASSISTANT: 'emotionalAssistant',
  STREAMING_VOICE: 'streamingVoice',
};

export const NEED_UPDATE = {
  IS_UPDATED: 'isUpdated',
  IS_NEEDED: 'isNeeded',
  IS_RECOMMENDED: 'isRecommended',
};

export const DIARY_ENTRY_STEPS = {
  INTRO: 'INTRO',
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  WRITING: 'WRITING',
  RESULT: 'RESULT',
};

// types of box assistant status
export const BOX_ASSISTANT_STATUS = {
  OPEN: 'OPEN', // El asistente está vivo y abierto
  CLOSED: 'CLOSED', // El asistente está vivo y cerrado
};

export const BOX_ASSISTANT_PHASE = {
  RUNNING: 'RUNNING', // esta la caja abierta y ejecutandose
  BACKGROUND: 'BACKGROUND', // estaba corriendo y bajaron la caja
  DEAD: 'DEAD', // estado inicial o si se para el servicio para hacer otro chat fuera de la caja
};

// types of component that can live inside the box assistant
export const BOX_ASSISTANT_COMPONENTS = {
  CHAT_TEXT: 'CHAT_TEXT',
  CHAT_VOICE: 'CHAT_VOICE',
  DIGITAL_THERAPY: 'DIGITAL_THERAPY',
  DIARY: 'DIARY',
  EVALUATIONS: 'EVALUATIONS',
  EXERCISES: 'EXERCISES',
};

export const CHAT_TYPES = {
  TEXT: 'text',
  AUDIO: 'audio',
  ACTION: 'action',
};

export const STREAMING_VOICE_STATES = {
  VOID: 'VOID',
  RECORDING: 'RECORDING',
  PROCESSING: 'PROCESSING',
  PLAYING: 'PLAYING',
  AUTH_ERROR: 'AUTH_ERROR',
  PERMISSIONS_ERROR: 'PERMISSIONS_ERROR',
  ERROR: 'ERROR',
  FINISHED: 'FINISHED',
  NOT_INTERNET_REACHABLE: 'NOT_INTERNET_REACHABLE',
  NOT_TRANSCRIPTION: 'NOT_TRANSCRIPTION',
  RECONNECTING: 'RECONNECTING',
  NOT_RECONNECTED: 'NOT_RECONNECTED',
};

export const SOUND_LIBRARIES = {
  TRACK_PLAYER: 'TRACK_PLAYER',
  RN_SOUND: 'RN_SOUND',
};

export const EVALUATION_ANSWERS_TYPES = {
  SKIP_FACTOR: 'SKIP_FACTOR',
  FINISH_WITH_ABSENCE: 'FINISH_WITH_ABSENCE',
};

export const USER_SUBS_OPTIONS = {
  IS_FREE: 'IS_FREE',
  IS_PREMIUM_NO_SHARED_YET: 'IS_PREMIUM_NO_SHARED_YET',
  IS_PREMIUM_SHARED: 'IS_PREMIUM_SHARED',
  IS_FREE_SHARED: 'IS_FREE_SHARED',
  IS_PREMIUM_NO_CODE: 'IS_PREMIUM_NO_CODE',
};

export const CHAT_STATE_RESPONSE = {
  EXPECTING_RESPONSE: 'EXPECTING_RESPONSE',
  WAITING_FOR_RESPONSE: 'WAITING_FOR_RESPONSE',
  FINISH_MESSAGE: 'FINISH_MESSAGE',
  ERROR: 'ERROR',
  RECONNECTING: 'RECONNECTING',
  NOT_RECONNECTED: 'NOT_RECONNECTED',
};

export const STREAMING_STATUS = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
};
